<template>
  <div class="mt-[40px]">
    <div v-if="index < 1 || showRecommendations">
      <div v-if="index == firstAlternativeIndex" class="p-4 md:px-0 text-blue-1">
        <h6>Alternative investment {{ textOption }}</h6>
        <div class="py-2 paragraph-1" v-html="getAlternativeIntro()" />
      </div>
      <div v-if="item.tag === 'lifestage'" class="p-4 md:px-0 text-blue-1">
        <h6>Lifestage investment option</h6>
        <div class="py-2 paragraph-1">
          <p>
            In addition to your recommended and alternative investment options, you also have the
            choice of investing in a Lifestage investment option.
          </p>
          <br />
          <p>
            {{ lifestageOptionName }} Lifestage is an investment option that determines how your
            money is invested depending on your age. The investment mix is designed for people born
            within the same five-year period as you.
          </p>
          <br />
          <div v-if="intraSuperLifestage.footer" v-html="intraSuperLifestage.footer" />
        </div>
      </div>
      <div class="flex justify-center md:justify-start">
        <div
          :class="{
            'bg-blue-2': item.tag === 'current',
            'bg-otivo-red': item.tag === 'recommended' || item.tag === 'mysuper',
            'bg-grey-2': item.tag === 'alternative' || item.tag === 'lifestage',
          }"
          :data-test="`${item.tag}`"
          class="text-white rounded-t-sm py-[20px] md:py-[10px] px-[10px] md:px-[20px] button-2 capitalize w-full md:w-fit">
          {{ item.label }}
        </div>
        <div
          v-if="recentlySelected"
          class="menu-1 bg-otivo-red text-white rounded self-center p-1 mx-[10px]"
          :data-test="`${item.tag}RecentlySelected`">
          RECENTLY SELECTED
        </div>
      </div>
      <div
        id="investment-option-information"
        :data-test="`${item.tag}InvestmentOption`"
        class="rounded-b"
        :class="{ 'otivo-shadow': item.tag === 'recommended' }">
        <div
          class="p-[20px] bg-white border border-grey-4"
          @mouseout="emit('mouseout')"
          @mouseover="emit('mouseover', index)">
          <h5
            class="text-blue-1 md:text-grey-1 md:text-18px md:font-bold"
            data-test="investmentOptionName">
            {{ item.investment.name }}
          </h5>
          <div class="flex flex-col">
            <div class="flex flex-col gap-2 md:flex-row mt-[20px] md:mt-[40px] w-full">
              <div class="option-info-container">
                <div class="uppercase font-bold flex flex-row items-center menu-1">
                  Best Year
                  <InfoCircle
                    class=""
                    message="The best recorded historical annual return over the last 5 years" />
                </div>
                <div class="flex gap-2 items-center">
                  <template v-if="item.best_rate !== 0">
                    <arrow-side class="w-5 h-5 transform -rotate-90" fill="#0064FF" />
                    <div class="flex flex-row md:flex-col">
                      <span class="paragraph-1" data-test="bestYearReturn">{{
                        formatPercentage(item.best_rate)
                      }}</span>
                      <span class="paragraph-2" data-test="bestYear">({{ item.best_year }})</span>
                    </div>
                  </template>
                  <div v-else>
                    <span class="paragraph-1" data-test="bestYearReturn">Unavailable*</span>
                  </div>
                </div>
              </div>
              <div class="option-info-container">
                <div class="menu-1 uppercase font-bold flex flex-row gap-[10px] items-center">
                  Worst Year
                  <InfoCircle
                    class=""
                    message="The worst recorded historical annual return over the last 5 years" />
                </div>
                <div class="flex gap-2 items-center">
                  <template v-if="item.worst_rate !== 0">
                    <arrow-side class="w-5 h-5 transform rotate-90" fill="#FD8900" />
                    <div class="flex flex-row md:flex-col">
                      <span class="paragraph-1" data-test="worstYearReturn">
                        {{ formatPercentage(item.worst_rate) }}
                      </span>
                      <span class="paragraph-2" data-test="worstYear">({{ item.worst_year }})</span>
                    </div>
                  </template>
                  <div v-else>
                    <span class="paragraph-1" data-test="worstYearReturn">Unavailable*</span>
                  </div>
                </div>
              </div>
              <div class="option-info-container">
                <div class="uppercase font-bold flex flex-row items-center menu-1">
                  <p>Average return</p>
                  <InfoCircle
                    class="paragraph-4"
                    message="Net of fees and costs over the last 5 years. It’s used to measure the performance of an investment over a year" />
                </div>
                <div
                  v-if="item.limited_historical_returns && item.actual_return_year_2"
                  class="flex flex-col gap-2">
                  <div class="flex flex-row gap-2">
                    <NeedsWork />
                    <div class="flex flex-col">
                      <p class="paragraph-2 text-red-1">5 year returns are unavailable.*</p>
                      <span class="paragraph-1" data-test="annualReturn">
                        2 year return is
                        {{ formatPercentage(Number(item.actual_return_year_2.toFixed(2))) }}
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else class="flex gap-2 items-center">
                  <template v-if="item.actual_return_year_5">
                    <ArrowSide class="w-5 h-5 transform -rotate-90" fill="#0064FF" />
                    <span class="paragraph-1" data-test="annualReturn">{{
                      formatPercentage(Number(item.actual_return_year_5.toFixed(2)))
                    }}</span>
                  </template>
                  <span v-else class="paragraph-1" data-test="annualReturn"> Unavailable* </span>
                </div>
              </div>
              <div class="option-info-container">
                <div class="uppercase font-bold flex flex-row items-center menu-1">
                  Fees
                  <InfoCircle
                    class=""
                    message="These are the fees deducted from your account that cover administrative, investment, and transaction costs." />
                </div>
                <div class="flex paragraph-1 items-center" data-test="fees">
                  {{ formatPercentage(Number(item.percent_fees?.toFixed(2))) }}
                </div>
              </div>
              <div class="option-info-container">
                <div class="uppercase font-bold flex flex-row items-center menu-1">
                  Risk rating
                  <InfoCircle
                    class=""
                    message="A rating of 1-2 indicates lower risk and return, with more conservative investments. A rating of 3 represents medium risk and return, with a balance between conservative and growth investments. A rating of 4-5 means higher risk and return, with more growth-oriented investments. " />
                </div>
                <risk-rating-scale
                  :index="item.risk_profile_level"
                  :label="item.risk_profile"
                  class="w-22 h-full" />
              </div>
              <div class="flex items-center w-100% mt-[20px] md:mt-0">
                <OtivoButton
                  v-if="clientView"
                  :disabled="adviceLocked"
                  :large-text="true"
                  class="border w-100% md:w-fit md:self-start"
                  data-test="chooseBtn"
                  colour="white"
                  size="medium"
                  @click="selectNewInvestmentOption">
                  Choose
                </OtivoButton>
                <div v-else class="w-36 m-auto" />
              </div>
            </div>
            <div
              v-if="item.negative_returns !== 'unavailable'"
              class="flex flex-row gap-2 mt-[20px] items-center paragraph-2">
              <arrow-side class="w-[22px] md:w-[12px] self-start md:self-center" />
              <p class="menu-1 !normal-case">
                Over any 20 year period, there would be an estimated {{ item.negative_returns }} of
                negative returns, based on information from the
                {{ isCFSWhitelabel() ? 'CFS' : 'product' }} PDS.
              </p>
            </div>
            <div
              v-if="item.limited_historical_returns && item.actual_return_year_2"
              class="flex flex-col mt-[20px]">
              <p class="button-1">*Limited historical returns</p>
              <p class="paragraph-2 mt-[10px]">
                Your current portfolio has only 2 years of history, which is too short for assessing
                its potential returns. Our recommendations will focus on investment options with at
                least five years of history. If you choose to stay with your current investment
                option, you should review its performance each year.
              </p>
            </div>
            <div
              v-if="item.limited_historical_returns && !item.actual_return_year_2"
              class="flex flex-col mt-[20px]">
              <p class="button-1">*5 year performance data is unavailable for this investment.</p>
            </div>
          </div>
        </div>
        <Collapse
          v-if="item.tag !== 'current'"
          :default-open="index === 1"
          class="button-1 text-grey-1 rounded-t-none border-t-0 rounded-b bg-grey-5 w-100% shadow-none">
          <template #heading>
            <div class="flex justify-between button-1 ml-2">
              <div v-if="item.tag === 'recommended'">
                Why we recommend {{ item.investment.name }}
              </div>
              <div v-else>Investment recommendation criteria</div>
            </div>
          </template>
          <template #body>
            <div v-if="index === 1" class="p-6 paragraph-2">
              <div
                v-if="item.tag === 'recommended'"
                class="leading-normal"
                v-html="item.copy.intro_1" />
              <div
                v-else-if="item.tag === 'mysuper'"
                class="leading-normal"
                v-html="item.copy.intro_1" />

              <template v-if="item.copy.reasons?.length">
                <p class="mt-[20px]">
                  <span v-if="item.tag === 'recommended'">
                    Compared to your current investment, the recommended option has had:
                  </span>
                  <span v-else> {{ item.investment.name }} is an alternative because: </span>
                </p>
                <ul
                  v-for="listItem in item.copy.reasons"
                  :key="listItem + index"
                  class="list-disc ml-6">
                  <li>{{ listItem }}</li>
                </ul>
              </template>
              <p
                class="mt-[20px]"
                v-if="
                  item.tag === 'recommended' &&
                  getCurrentSuperResults[0]?.tag === 'current' &&
                  getCurrentSuperResults[0]?.limited_historical_returns
                ">
                We have not recommended your current investment because it does not have a 5-year
                history. We need this track record to see how it might perform in the future.
              </p>
            </div>
            <div v-else class="p-4 paragraph-2">
              <div>
                Compared to your current investment, this alternative option has had:
                <ul class="list-disc ml-6">
                  <li v-if="volatilityComparison !== 'Unavailable'">
                    {{ volatilityComparison }}
                    <span class="lowercase">
                      risk, because of a {{ volatilityComparison }} percentage of growth assets.
                    </span>
                  </li>
                  <li v-if="returnsComparison !== 'Unavailable'">
                    {{ returnsComparison }} annual returns.
                  </li>
                  <li v-if="feesComparison !== 'Unavailable'">
                    {{ feesComparison }} fees. Fees are deducted from your super balance and can
                    impact your retirement savings over time.
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </Collapse>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { formatPercentage } from '@/lib/helpers'
import RiskRatingScale from '../OptimizeAdvice/Super/Results/RiskRatingScale.vue'
import ArrowSide from '@/components/icons/ArrowSide.vue'
import InfoCircle from '@/components/InfoCircle/InfoCircle.vue'
import Collapse from '@/components/SaveAndCollapse/Collapse.vue'
import OtivoButton from '@/components/OtivoButton.vue'
import { InvestmentOption } from '@/components/SuperWidget/SuperWidgetAdviceType'
import { ComponentContentKeysType } from '@/types/GlobalTypes'
import NeedsWork from '@/components/icons/NeedsWork.vue'
import { useSuperStore } from '@/store/pinia/SuperStore.ts'
import { IntrafundAdviceRequest } from '@/types/api/super/SuperObject.ts'
import { isCFSWhitelabel } from '@/composables/isCFSWhitelabel.ts'

interface Props {
  index: number
  hasMultipleAlternatives: boolean
  item: InvestmentOption
  showRecommendations: boolean
  getCurrentSuperResults: Array<InvestmentOption>
  clientView: boolean
  intraSuperLifestage: ComponentContentKeysType
  adviceLocked: boolean
}

const props = withDefaults(defineProps<Props>(), {
  clientView: false,
})
const emit = defineEmits(['mouseover', 'mouseout', 'selectionUpdated'])
const superStore = useSuperStore()
const adviceRequestObject = computed(
  () => superStore.getActiveSuperObject.advice_request as IntrafundAdviceRequest,
)

const textOption = computed(() => 'option' + (props.hasMultipleAlternatives ? 's' : ''))
const textAlternative = computed(() =>
  props.hasMultipleAlternatives ? 'some alternatives' : 'alternative',
)
const getAlternativeIntro = () => {
  const introCopy = props.getCurrentSuperResults.filter((el) => {
    return el.tag === 'alternative'
  })
  if (introCopy.length > 0) {
    return (
      introCopy[0].copy.intro_1 ??
      `<span>Different options come with different levels of risk and possible returns. If you want to check out a broader range of ${textOption.value} with similarities to our recommended investment option, we've provided ${textAlternative.value}.</span>`
    )
  }
  return ''
}
const recentlySelected = computed(() => {
  return (
    (adviceRequestObject.value?.advice_stage === 4 &&
      adviceRequestObject.value?.requested_change_id === props.item.investment.id) ||
    (adviceRequestObject.value?.advice_stage === 3 &&
      adviceRequestObject.value?.previous_investment_id === props.item.investment.id)
  )
})

const returnsComparison = computed(() => compareInvestmentOptionData('actual_return_year_5'))
const feesComparison = computed(() => compareInvestmentOptionData('percent_fees'))
const volatilityComparison = computed(() => compareInvestmentOptionData('risk_profile_level'))

const compareInvestmentOptionData = (key: keyof InvestmentOption) => {
  const itemValue = props.item[key]
  const currentResultValue = props.getCurrentSuperResults[0]?.[key]

  if (!itemValue || !currentResultValue) {
    return 'Unavailable'
  }

  if (isNaN(currentResultValue) || isNaN(itemValue)) {
    return 'Unavailable'
  }

  if (itemValue > currentResultValue) {
    return 'Higher'
  } else if (itemValue < currentResultValue) {
    return 'Lower'
  } else if (itemValue == currentResultValue) {
    return 'The same'
  } else {
    return 'Unavailable'
  }
}

const selectNewInvestmentOption = () => {
  emit('selectionUpdated', props.item)
}

const lifestageOptionName = computed(() => {
  if (props.item.tag != 'lifestage') return ''

  let name = props.item.investment.name
  const indexOf = name.toLowerCase().indexOf('lifestage') // remove the word lifestage from the name
  return name
    .substring(0, indexOf)
    .replace(/[^a-zA-Z ]/g, '')
    .trim() // remove any numbers or special char
})

const firstAlternativeIndex = computed(() =>
  props.getCurrentSuperResults.findIndex((val) => val.tag === 'alternative'),
)
</script>

<style lang="scss" scoped>
.tooltip > .tooltip-inner {
  background-color: #000 !important;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.option-info-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--blue-5);
  padding: 10px 0;
  @media screen and (min-width: 768px) {
    justify-content: normal;
    flex-direction: column;
    border-bottom: none;
    gap: 20px;
    padding: 0;
  }
}
</style>
